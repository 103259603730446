import React from 'react';
import Layout from './index';
import messages from '../data/messages/ja';

if (!Intl.PluralRules) {
    require("@formatjs/intl-pluralrules/polyfill")
    require("@formatjs/intl-pluralrules/dist/locale-data/ja") // Add locale data for de
  }
  
  if (!Intl.RelativeTimeFormat) {
    require("@formatjs/intl-relativetimeformat/polyfill")
    require("@formatjs/intl-relativetimeformat/dist/locale-data/ja") // Add locale data for de
  }
export default (props) => (
  <Layout
    {...props}
    i18nMessages={messages}
  />);