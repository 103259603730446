module.exports = {
  selectLanguage: "言語を選択してください。",
  displayName: "日本語",
  categories: "カテゴリー",
  read: "読む",
  price: "価格",
  lastUpdate: "最終更新日",
  free: "無料公開中",
  Software: "ソフトウェア",
  "Machine Learning": "機械学習",
  Python: "Python",
  Azure: "Azure",
  "ARM Template": "ARM Template",
  Tags: "タグ",
  Home: "ホーム",
  "API Management": "API Management",
  "Key Vault": "Key Vault",
  "Contact Form": "お問い合わせフォーム",
  "Cancel": "キャンセル",
  "Submit": "送信",
  "Contact Message": "我々のWebサイトにフィードバックをお寄せいただければ幸いです。",
  "Flutter": "Flutter"
}
